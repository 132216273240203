const STATUS_CHANGE = "STATUS_CHANGE";
const STATUS_EMAIL = "STATUS_EMAIL";
const STATUS_UPLOAD = "STATUS_UPLOAD";
const STATUS_APPROVE = "STATUS_APPROVE";
const STATUS_APPROVE_LIABILITY = "STATUS_APPROVE_LIABILITY";
const STATUS_APPROVE_AUTO = "STATUS_APPROVE_AUTO";
const STATUS_APPROVE_WORKERS_COMPENSATION =
  "STATUS_APPROVE_WORKERS_COMPENSATION";
const STATUS_APPROVE_UMBRELLA = "STATUS_APPROVE_UMBRELLA";
const STATUS_APPROVE_CRIME = "STATUS_APPROVE_CRIME";
const STATUS_APPROVE_GARAGE = "STATUS_APPROVE_GARAGE";
const STATUS_APPROVE_EPLI = "STATUS_APPROVE_EPLI";
const STATUS_APPROVE_CYBER = "STATUS_APPROVE_CYBER";
const STATUS_APPROVE_PRO_LIAB = "STATUS_APPROVE_PRO_LIAB";
const STATUS_APPROVE_POL_LIAB = "STATUS_APPROVE_POL_LIAB";
const STATUS_APPROVE_IM = "STATUS_APPROVE_IM";
const STATUS_APPROVE_PRO_INS = "STATUS_APPROVE_PRO_INS";
const STATUS_APPROVE_ADDITIONAL_INFORMATION =
  "STATUS_APPROVE_ADDITIONAL_INFORMATION";
const STATUS_EMAIL_ISSUE = "STATUS_EMAIL_ISSUE";
const STATUS_EMAIL_EXPIRATION = "STATUS_EMAIL_EXPIRATION";
const STATUS_EMAIL_VERIFICATION_NOT_COMPLIANT =
  "STATUS_EMAIL_VERIFICATION_NOT_COMPLIANT";
const STATUS_EMAIL_VERIFICATION_NOT_VERIFIED =
  "STATUS_EMAIL_VERIFICATION_NOT_VERIFIED";
const STATUS_EMAIL_VERIFICATION_EXPIRING = "STATUS_EMAIL_VERIFICATION_EXPIRING";
const STATUS_EMAIL_VERIFICATION_LAPSED = "STATUS_EMAIL_VERIFICATION_LAPSED";
const STATUS_POLICY_STATUS_CHANGE = "STATUS_POLICY_STATUS_CHANGE";
const STATUS_COMPLIANCE_STATUS_CHANGE = "STATUS_COMPLIANCE_STATUS_CHANGE";

const REQUIREMENT_STATUS_ONBOARD = "onboard";
const REQUIREMENT_STATUS_DASHBOARD = "dashboard";

const DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER = "rikorCustomer";
const DO_NOT_CONTACT_OPTION_INACTIVE_ZEE = "inActiveZee";
const DO_NOT_CONTACT_OPTION_IS_LEAD = "isLead";
const DO_NOT_CONTACT_OPTION_NOT_OPEN = "notOpen";
const DO_NOT_CONTACT_OPTION_NOT_COVERAGE = "notCoverage";
const DO_NOT_CONTACT_OPTION_CANADA_BUSINESS = "canadianBusiness";
const DO_NOT_CONTACT_OPTION_CANCEL_POLICY = "cancelPolicy";
const DO_NOT_CONTACT_OPTION_NONE = null;

const DO_NOT_CONTACT_LABEL_RIKOR_CUSTOMER = "Rikor Customer";
const DO_NOT_CONTACT_LABEL_INACTIVE_ZEE = "Inactive Zee";
const DO_NOT_CONTACT_LABEL_IS_LEAD = "New Lead";
const DO_NOT_CONTACT_LABEL_NOT_OPEN = "Not Yet Open for Business";
const DO_NOT_CONTACT_LABEL_NOT_COVERAGE = "Rikor Cannot Obtain Coverage";
const DO_NOT_CONTACT_LABEL_CANADA_BUSINESS = "Canadian Business";
const DO_NOT_CONTACT_LABEL_CANCEL_POLICY = "Cancel Policy";
const DO_NOT_CONTACT_LABEL_ALL = "Non Rikor Customer";

const DO_NOT_CONTACT_ALL = "all";

const PERMISSION_TIER_1 = "TIER_1";
const PERMISSION_TIER_2 = "TIER_2";
const PERMISSION_TIER_3 = "TIER_3";

const USER_TYPE_ADMIN = "adminUser";
const USER_TYPE_FRANCHISOR = "regUser";
const USER_TYPE_FRANCHISEE = "coiUser";

const INSURANCE_STATUS_NONE = "Document Missing";
const INSURANCE_STATUS_COMPLIANT = "Compliant";
const INSURANCE_STATUS_IN_PLACE = "In Place";
const INSURANCE_STATUS_NON_COMPLIANT = "Non-Compliant";
const INSURANCE_STATUS_NON_VERIFIED = "Not-Verified";
const INSURANCE_STATUS_PENDING = "Pending";
const INSURANCE_STATUS_ALL = "All";

const STATUS_COMPLETE = "Complete";
const STATUS_PENDING = "Pending";

const STATUS_SECTION_APPROVE = "approved";
const STATUS_SECTION_PENDING = "pending";

const DOCUMENT_STATUS_ARCHIVED = "archived";
const DOCUMENT_STATUS_REJECT = "reject";
const DOCUMENT_STATUS_ACTIVE = "active";

const STATUS_EXPIRATION_THIRTY = "expire_thirty";
const STATUS_EXPIRATION_THIRTY_LABEL = "Expiration under 30 days";
const STATUS_EXPIRATION_SIXTY = "expire_sixty";
const STATUS_EXPIRATION_SIXTY_LABEL = "Expiration under 60 days";
const STATUS_EXPIRATION_NINETY = "expire_ninety";
const STATUS_EXPIRATION_NINETY_LABEL = "Expiration under 90 days";
const STATUS_EXPIRATION_TODAY = "expire_today";
const STATUS_EXPIRATION_TODAY_LABEL = "As of Today";
const STATUS_EXPIRATION_NONE = "expire_none";
const STATUS_EXPIRATION_NONE_LABEL = "All Franchisees";

const CATEGORY_DEVELOPMENT = "In Development";
const CATEGORY_ACTIVE = "Active Franchisees";
const CATEGORY_TERMINATED = "Terminated Franchisees";
const CATEGORY_ALL = "CATEGORY_ALL";

const TIME_PERIOD_ALL = "TIME_PERIOD_ALL";
const TIME_PERIOD_ONE_MONTH = "TIME_PERIOD_ONE_MONTH";
const TIME_PERIOD_TWO_MONTH = "TIME_PERIOD_TWO_MONTH";
const TIME_PERIOD_THREE_MONTH = "TIME_PERIOD_THREE_MONTH";

const DOC_MAX_LIMIT = 9999;

const EXPIRATION_OPTION = [
  {
    value: STATUS_EXPIRATION_THIRTY,
    label: STATUS_EXPIRATION_THIRTY_LABEL
  },
  {
    value: STATUS_EXPIRATION_SIXTY,
    label: STATUS_EXPIRATION_SIXTY_LABEL
  },
  {
    value: STATUS_EXPIRATION_NINETY,
    label: STATUS_EXPIRATION_NINETY_LABEL
  },
  {
    value: STATUS_EXPIRATION_TODAY,
    label: STATUS_EXPIRATION_TODAY_LABEL
  },
  {
    value: STATUS_EXPIRATION_NONE,
    label: STATUS_EXPIRATION_NONE_LABEL
  }
];

const CATEGORY_OPTION = [
  {
    value: CATEGORY_DEVELOPMENT,
    label: "In Development"
  },
  {
    value: CATEGORY_ACTIVE,
    label: "Active Franchisees"
  },
  {
    value: CATEGORY_TERMINATED,
    label: "Terminated Franchisees"
  },
  {
    value: CATEGORY_ALL,
    label: "All Franchisees"
  }
];

const DO_NOT_CONTACT_OPTION = [
  {
    value: DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
    label: DO_NOT_CONTACT_LABEL_RIKOR_CUSTOMER
  },
  {
    value: DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
    label: DO_NOT_CONTACT_LABEL_INACTIVE_ZEE
  },
  {
    value: DO_NOT_CONTACT_OPTION_IS_LEAD,
    label: DO_NOT_CONTACT_LABEL_IS_LEAD
  },
  {
    value: DO_NOT_CONTACT_OPTION_NOT_OPEN,
    label: DO_NOT_CONTACT_LABEL_NOT_OPEN
  },
  {
    value: DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
    label: DO_NOT_CONTACT_LABEL_NOT_COVERAGE
  },
  {
    value: DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
    label: DO_NOT_CONTACT_LABEL_CANADA_BUSINESS
  },
  {
    value: DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
    label: DO_NOT_CONTACT_LABEL_CANCEL_POLICY
  },
  {
    value: DO_NOT_CONTACT_ALL,
    label: DO_NOT_CONTACT_LABEL_ALL
  }
];

const TIME_PERIOD_OPTION = [
  { value: TIME_PERIOD_ALL, label: "All Franchisees" },
  { value: TIME_PERIOD_ONE_MONTH, label: "30 Days" },
  { value: TIME_PERIOD_TWO_MONTH, label: "2 Months" },
  { value: TIME_PERIOD_THREE_MONTH, label: "3 Months" }
];

export {
  STATUS_CHANGE,
  STATUS_EMAIL,
  STATUS_UPLOAD,
  STATUS_APPROVE,
  STATUS_APPROVE_LIABILITY,
  STATUS_APPROVE_AUTO,
  STATUS_APPROVE_WORKERS_COMPENSATION,
  STATUS_APPROVE_UMBRELLA,
  STATUS_APPROVE_CRIME,
  STATUS_APPROVE_GARAGE,
  STATUS_APPROVE_EPLI,
  STATUS_APPROVE_CYBER,
  STATUS_APPROVE_PRO_LIAB,
  STATUS_APPROVE_POL_LIAB,
  STATUS_APPROVE_IM,
  STATUS_APPROVE_PRO_INS,
  STATUS_APPROVE_ADDITIONAL_INFORMATION,
  STATUS_SECTION_APPROVE,
  STATUS_SECTION_PENDING,
  STATUS_EMAIL_ISSUE,
  STATUS_EMAIL_EXPIRATION,
  STATUS_EMAIL_VERIFICATION_NOT_COMPLIANT,
  STATUS_EMAIL_VERIFICATION_NOT_VERIFIED,
  STATUS_EMAIL_VERIFICATION_EXPIRING,
  STATUS_EMAIL_VERIFICATION_LAPSED,
  STATUS_POLICY_STATUS_CHANGE,
  STATUS_COMPLIANCE_STATUS_CHANGE,
  PERMISSION_TIER_1,
  PERMISSION_TIER_2,
  PERMISSION_TIER_3,
  REQUIREMENT_STATUS_ONBOARD,
  REQUIREMENT_STATUS_DASHBOARD,
  USER_TYPE_ADMIN,
  USER_TYPE_FRANCHISOR,
  USER_TYPE_FRANCHISEE,
  INSURANCE_STATUS_NONE,
  INSURANCE_STATUS_COMPLIANT,
  INSURANCE_STATUS_IN_PLACE,
  INSURANCE_STATUS_NON_COMPLIANT,
  INSURANCE_STATUS_NON_VERIFIED,
  INSURANCE_STATUS_PENDING,
  INSURANCE_STATUS_ALL,
  STATUS_COMPLETE,
  STATUS_PENDING,
  DOCUMENT_STATUS_ARCHIVED,
  DOCUMENT_STATUS_ACTIVE,
  DOCUMENT_STATUS_REJECT,
  DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
  DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
  DO_NOT_CONTACT_OPTION_IS_LEAD,
  DO_NOT_CONTACT_OPTION_NOT_OPEN,
  DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
  DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
  DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
  DO_NOT_CONTACT_OPTION_NONE,
  DO_NOT_CONTACT_LABEL_RIKOR_CUSTOMER,
  DO_NOT_CONTACT_LABEL_INACTIVE_ZEE,
  DO_NOT_CONTACT_LABEL_IS_LEAD,
  DO_NOT_CONTACT_LABEL_NOT_OPEN,
  DO_NOT_CONTACT_LABEL_NOT_COVERAGE,
  DO_NOT_CONTACT_LABEL_CANADA_BUSINESS,
  DO_NOT_CONTACT_LABEL_CANCEL_POLICY,
  DO_NOT_CONTACT_ALL,
  DO_NOT_CONTACT_LABEL_ALL,
  DOC_MAX_LIMIT,
  STATUS_EXPIRATION_THIRTY,
  STATUS_EXPIRATION_THIRTY_LABEL,
  STATUS_EXPIRATION_SIXTY,
  STATUS_EXPIRATION_SIXTY_LABEL,
  STATUS_EXPIRATION_NINETY,
  STATUS_EXPIRATION_NINETY_LABEL,
  STATUS_EXPIRATION_TODAY,
  STATUS_EXPIRATION_TODAY_LABEL,
  STATUS_EXPIRATION_NONE,
  STATUS_EXPIRATION_NONE_LABEL,
  CATEGORY_DEVELOPMENT,
  CATEGORY_ACTIVE,
  CATEGORY_TERMINATED,
  CATEGORY_ALL,
  TIME_PERIOD_ALL,
  TIME_PERIOD_ONE_MONTH,
  TIME_PERIOD_TWO_MONTH,
  TIME_PERIOD_THREE_MONTH,
  EXPIRATION_OPTION,
  CATEGORY_OPTION,
  DO_NOT_CONTACT_OPTION,
  TIME_PERIOD_OPTION
};
