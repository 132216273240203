/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input as AntInput, Button, Switch, Select, Radio  } from 'antd';
import { Descriptions } from "antd";
import { DeleteOutlined, UserAddOutlined, UserOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";

import { Input } from "@components";
import { ButtonGroup } from "@components";
import {
  TYPE_INPUT_PRIMARY,
  TYPE_INPUT_PHONE,
  TYPE_INPUT_YEAR,
  TYPE_INPUT_ADDRESS,
  TYPE_INPUT_DATE,
  TYPE_INPUT_DIGIT,
  TYPE_INPUT_TAX_ID,
  TYPE_INPUT_VIN,
  TYPE_INPUT_CURRENCY
} from "@assets/const/ui";
import { Card, Loading, ScreenMask } from "@components";
import { formatNumber, validateNumber, validateEmail } from "@utils/common";
import { BUTTON_GROUP_TYPE_SWITCH } from "@components/button-group";
import { ROLE_LIST } from "@assets/const/ui";
import "./style.scss";
import { min } from 'lodash';

export default function ZorPocEdit({ isOpen, onClose, userData, deleteUser, onChange, handleSave }) {
  const [firstName, setFirstName] = useState(userData.firstName);
  const [lastName, setLastName] = useState(userData.lastName);
  const [email, setEmail] = useState(userData.email);
  const [telephone, setTelephone] = useState(userData.telephone);
  const [isPrimary, setIsPrimary] = useState(userData.isPrimary);
  const [isActive, setIsActive] = useState(userData.isActive);
  const [role, setRole] = useState(userData.role);
  const [isReport, setIsReport] = useState(userData.isReport);
  const [reportSchedule, setReportSchedule] = useState(userData.reportSchedule);
  const [isBindPolicy, setIsBindPolicy] = useState(userData.isBindPolicy);
  const [bindPolicySchedule, setBindPolicySchedule] = useState(userData.bindPolicySchedule);
  const [isLapseCoverage, setIsLapseCoverage] = useState(userData.isLapseCoverage);
  const [lapseCoverageSchedule, setLapseCoverageSchedule] = useState(userData.lapseCoverageSchedule);
  const [isInactiveZee, setIsInactiveZee] = useState(userData.isInactiveZee);
  const [inactiveZeeSchedule, setInactiveZeeSchedule] = useState(userData.inactiveZeeSchedule);
  const [isNetPromoter, setIsNetPromoter] = useState(userData.isNetPromoter);
  const [netPromoterSchedule, setNetPromoterSchedule] = useState(userData.netPromoterSchedule);
  const [pocConfirm, setPocConfirm] = useState(userData.pocConfirm);
  const [pocConfirmSchedule, setPocConfirmSchedule] = useState(userData.pocConfirmSchedule);
  const [newLeadReport, setNewLeadReport] = useState(userData.newLeadReport);
  const [newLeadSchedule, setNewLeadSchedule] = useState(userData.newLeadSchedule);
  const [coiUploadReport, setCoiUploadReport] = useState(userData.coiUploadReport);
  const [coiUploadSchedule, setCoiUploadSchedule] = useState(userData.coiUploadSchedule);
  const [consultIntroEmail, setConsultIntroEmail] = useState(userData.consultIntroEmail);
  const [consultIntroSchedule, setConsultIntroSchedule] = useState(userData.consultIntroSchedule);
  const [errors, setErrors] = useState({});

  function handleValidation() {
    const errObj = {};
    const tel = telephone?.replace(/[^0-9]/g, '');

    if (!firstName || !lastName || !email) {
      return false;
    }

    if (tel.length > 0 && tel.length !== 10) {
      errObj.telephone = "Please enter a valid cell phone number";
      setErrors(errObj);
      return false
    } else {
      delete errors.telephone;
    }

    if (validateNumber(telephone) === false) {
      return false;
    }
    return true;
  }

  async function handleSubmit() {
    console.log("errors", errors);
    if (!handleValidation()) {
      return;
    }

    const data = {
      firstName,
      lastName,
      email,
      telephone,
      isPrimary,
      isActive,
      role,
      isReport,
      reportSchedule,
      isBindPolicy,
      bindPolicySchedule,
      isLapseCoverage,
      lapseCoverageSchedule,
      isInactiveZee,
      inactiveZeeSchedule,
      isNetPromoter,
      netPromoterSchedule,
      pocConfirm,
      pocConfirmSchedule,
      newLeadReport,
      newLeadSchedule,
      coiUploadReport,
      coiUploadSchedule,
      consultIntroEmail,
      consultIntroSchedule
    }

    Object.entries(data).forEach(([key, value]) => {
      onChange(key, value)
    });

    onClose();
    await handleSave();
  }

  const SCHEDULE_OPTIONS = [
    {
      label: "1d",
      value: "1d"
    },
    {
      label: "3d",
      value: "3d"
    },
    {
      label: "5d",
      value: "5d"
    },
    {
      label: "15d",
      value: "15d"
    },
    {
      label: "30d",
      value: "30d"
    }
  ];

  return (
    <Modal
      title={<span className="modal-title">Edit User Contact</span>}
      className="poc-edit zor-poc-edit"
      open={isOpen}
      footer={null}
      onCancel={onClose}
    >
      <Form className='poc-cr zor-poc-form'>
        <div className='form-group-container'>
          <div>
            <label className='input-title'>First Name</label>
            <Form.Item
              rules={[{ required: true, message: 'Please input your first name.' }]}
            >
              <AntInput
                required={true}
                placeholder='First Name'
                size='large'
                value={firstName}
                onChange={(evt) => setFirstName(evt.target.value)}
              />
            </Form.Item>
          </div>
          <div>
            <label className='input-title'>Last Name</label>
            <Form.Item
              rules={[{ required: true, message: 'Please input your last name.' }]}
            >
              <AntInput
                required={true}
                placeholder='Last Name'
                size='large'
                value={lastName}
                onChange={(evt) => setLastName(evt.target.value)}
              />
            </Form.Item>
          </div>
        </div>
        <div className='form-group-container'>
          <div>
            <label className='input-title'>Email</label>
            <Form.Item
              rules={[{ required: true, message: 'Please input your email.' }]}
            >
              <AntInput
                required={true}
                placeholder='Email'
                type='email'
                size='large'
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </Form.Item>
          </div>
          <div>
            <label className='input-title'>Cell Phone</label>
            <Form.Item
              rules={[{ required: true, message: 'Please input your telephone.', min: 10, max: 10 }]}
            >
              <Input
                style={{ fontSize: '14px'}}
                inputType={TYPE_INPUT_PHONE}
                {...{
                  min: 10,
                  max: 10,
                  value: telephone,
                  onChange: (evt) => setTelephone(evt.target.value)
                }}
              />
              {errors?.telephone && <span className='error'>{errors.telephone}</span>}
            </Form.Item>
          </div>
        </div>
        <div className='form-group-container'>
          <div>
            <label className='input-title'>Role</label>
            <Form.Item
              rules={[{ required: true, message: 'Please input your role.' }]}
            >
              <Select
                style={{ width: '100%', height: '40px' }}
                value={role}
                onChange={(val) => setRole(val)}
                options={ROLE_LIST}
              />
            </Form.Item>
          </div>
        </div>
        <div className='form-group-container'>
          <div>
            <label className='input-title'>Primary</label>
            <Form.Item
              rules={[{ required: true, message: 'Please input your primary.' }]}
            >
              <Switch
                checked={isPrimary}
                onChange={(check) => setIsPrimary(check)}
              />
            </Form.Item>
          </div>
          <div>
            <label className='input-title'>Active</label>
            <Form.Item
              rules={[{ required: true, message: 'Please input your active.' }]}
            >
              <Switch
                checked={isActive}
                onChange={(check) => setIsActive(check)}
              />
            </Form.Item>
          </div>
        </div>
        <div className='form-single-container'>
          <h2>Reports</h2>
          <div className='report-item'>
            <div>
              <Form.Item
                rules={[{ required: true, message: 'Please input your reports.' }]}
              >
                <div className='report-item-switch'>
                  <label className='input-title' style={{ paddingRight: '10px' }}>Executive Summary Report - Insurance Report</label>
                  <Switch
                    checked={isReport}
                    onChange={(check) => setIsReport(check)}
                  />
                </div>
              </Form.Item>
            </div>
            <div className='report-item-btn'>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={reportSchedule}
                onChange={(check) => setReportSchedule(check)}
              />
            </div>
          </div>
          <div className='report-item'>
            <div>
              <Form.Item
                rules={[{ required: true, message: 'Please input your reports.' }]}
              >
                <div className='report-item-switch'>
                  <label className='input-title' style={{ paddingRight: '10px' }}>Recently Insured By Rikor Report Report</label>
                  <Switch
                    checked={isBindPolicy}
                    onChange={(check) => setIsBindPolicy(check)}
                  />
                </div>
              </Form.Item>
            </div>
            <div className='report-item-btn'>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={bindPolicySchedule}
                onChange={(check) => setBindPolicySchedule(check)}
              />
            </div>
          </div>
          <div className='report-item'>
            <div>
              <Form.Item
                rules={[{ required: true, message: 'Please input your reports.' }]}
              >
                <div className='report-item-switch'>
                  <label className='input-title' style={{ paddingRight: '10px' }}>Franchisee Lapse in Coverage Report</label>
                  <Switch
                    checked={isLapseCoverage}
                    onChange={(check) => setIsLapseCoverage(check)}
                  />
                </div>
              </Form.Item>
            </div>
            <div className='report-item-btn'>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={lapseCoverageSchedule}
                onChange={(check) => setLapseCoverageSchedule(check)}
              />
            </div>
          </div>
          <div className='report-item'>
            <div>
              <Form.Item
                rules={[{ required: true, message: 'Please input your reports.' }]}
              >
                <div className='report-item-switch'>
                  <label className='input-title' style={{ paddingRight: '10px' }}>Inactive Franchisee Report</label>
                  <Switch
                    checked={isInactiveZee}
                    onChange={(check) => setIsInactiveZee(check)}
                  />
                </div>
              </Form.Item>
            </div>
            <div className='report-item-btn'>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={inactiveZeeSchedule}
                onChange={(check) => setInactiveZeeSchedule(check)}
              />
            </div>
          </div>
          <div className='report-item'>
            <div>
              <Form.Item
                rules={[{ required: true, message: 'Please input your reports.' }]}
              >
                <div className='report-item-switch'>
                  <label className='input-title' style={{ paddingRight: '10px' }}>Non-Compliant Franchisee Report</label>
                  <Switch
                    checked={isNetPromoter}
                    onChange={(check) => setIsNetPromoter(check)}
                  />
                </div>
              </Form.Item>
            </div>
            <div className='report-item-btn'>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={netPromoterSchedule}
                onChange={(check) => setNetPromoterSchedule(check)}
              />
            </div>
          </div>
          <div className='report-item'>
            <div>
              <Form.Item
                rules={[{ required: true, message: 'Please input your reports.' }]}
              >
                <div className='report-item-switch'>
                  <label className='input-title' style={{ paddingRight: '10px' }}>POC Confirmation Request</label>
                  <Switch
                    checked={pocConfirm}
                    onChange={(check) => setPocConfirm(check)}
                  />
                </div>
              </Form.Item>
            </div>
            <div className='report-item-btn'>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={pocConfirmSchedule}
                onChange={(check) => setPocConfirmSchedule(check)}
              />
            </div>
          </div>
          <div className='report-item'>
            <div>
              <Form.Item
                rules={[{ required: true, message: 'Please input your reports.' }]}
              >
                <div className='report-item-switch'>
                  <label className='input-title' style={{ paddingRight: '10px' }}>New Lead Report</label>
                  <Switch
                    checked={newLeadReport}
                    onChange={(check) => setNewLeadReport(check)}
                  />
                </div>
              </Form.Item>
            </div>
            <div className='report-item-btn'>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={newLeadSchedule}
                onChange={(check) => setNewLeadSchedule(check)}
              />
            </div>
          </div>
          <div className='report-item'>
            <div>
              <Form.Item
                rules={[{ required: true, message: 'Please input your reports.' }]}
              >
                <div className='report-item-switch'>
                  <label className='input-title' style={{ paddingRight: '10px' }}>COI Upload Report</label>
                  <Switch
                    checked={coiUploadReport}
                    onChange={(check) => setCoiUploadReport(check)}
                  />
                </div>
              </Form.Item>
            </div>
            <div className='report-item-btn'>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={coiUploadSchedule}
                onChange={(check) => setCoiUploadSchedule(check)}
              />
            </div>
          </div>
          <div className='report-item'>
            <div>
              <Form.Item
                rules={[{ required: true, message: 'Please input your reports.' }]}
              >
                <div className='report-item-switch'>
                  <label className='input-title' style={{ paddingRight: '10px' }}>Consultant Introduction Email</label>
                  <Switch
                    checked={consultIntroEmail}
                    onChange={(check) => setConsultIntroEmail(check)}
                  />
                </div>
              </Form.Item>
            </div>
            <div className='report-item-btn'>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={consultIntroSchedule}
                onChange={(check) => setConsultIntroSchedule(check)}
              />
            </div>
          </div>
        </div>
        <div className="poc-cr-btns form zor-poc-btns">
          <Button type="default" size="large" onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit" size="large" onClick={handleSubmit}>Submit</Button>
        </div>
      </Form>
    </Modal>
  )
}

{/* <div className="contact_item">
      <div className="form-group-one-two-container">
        <div className="">

        </div>
        <div className="contact_item-wrapper">
          <div className="contact_item-wrapper-activity">

            <div className="contact_item-wrapper-activity-item">
              <span className="contact_item-wrapper-activity-item-label">
                Role
              </span>
              <Select
                style={{ width: "120px" }}
                value={data.role}
                onChange={(val) => onChange("role", val)}
                options={ROLE_LIST}
              />
            </div>
          </div>

          <div className="contact_item-wrapper-report">
            <label className="contact_item-wrapper-report-label">Reports</label>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isReport}
                  onChange={(check) => onChange("isReport", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Monthly Executive Summary - Insurance Report
                </span>
              </div>

              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.reportSchedule}
                onChange={(check) => onChange("reportSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isBindPolicy}
                  onChange={(check) => onChange("isBindPolicy", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Recently Insured By Rikor Report Report
                </span>
              </div>

              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.bindPolicySchedule}
                onChange={(check) => onChange("bindPolicySchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              Canceled Policies Notification
            </span>
            <Switch
              checked={data.isCancelPolicy}
              onChange={(check) => onChange("isCancelPolicy", check)}
            />
          </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isLapseCoverage}
                  onChange={(check) => onChange("isLapseCoverage", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Franchisee Lapse in Coverage Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.lapseCoverageSchedule}
                onChange={(check) => onChange("lapseCoverageSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isInactiveZee}
                  onChange={(check) => onChange("isInactiveZee", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Inactive Franchisee Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.inactiveZeeSchedule}
                onChange={(check) => onChange("inactiveZeeSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isNetPromoter}
                  onChange={(check) => onChange("isNetPromoter", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Non-Compliant Franchisee Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.netPromoterSchedule}
                onChange={(check) => onChange("netPromoterSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.pocConfirm}
                  onChange={(check) => onChange("pocConfirm", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  POC Confirmation Request
                </span>
              </div>

              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.pocConfirmSchedule}
                onChange={(check) => onChange("pocConfirmSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.newLeadReport}
                  onChange={(check) => onChange("newLeadReport", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  New Lead Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.newLeadSchedule}
                onChange={(check) => onChange("newLeadSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.coiUploadReport}
                  onChange={(check) => onChange("coiUploadReport", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  COI Upload Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.coiUploadSchedule}
                onChange={(check) => onChange("coiUploadSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.consultIntroEmail}
                  onChange={(check) => onChange("consultIntroEmail", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Consultant Introduction Email
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.consultIntroSchedule}
                onChange={(check) => onChange("consultIntroSchedule", check)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="contact_item-action">
        <CloseOutlined
          className="contact_item-action-icon"
          onClick={handleClose}
        />
      </div>
    </div> */}
