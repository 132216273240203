import React from "react";
import { Card } from "../../../../components";
import { Table } from "antd";
import {
  STATUS_CHANGE,
  STATUS_EMAIL,
  STATUS_UPLOAD,
  STATUS_APPROVE,
  STATUS_APPROVE_LIABILITY,
  STATUS_APPROVE_AUTO,
  STATUS_APPROVE_WORKERS_COMPENSATION,
  STATUS_APPROVE_UMBRELLA,
  STATUS_APPROVE_ADDITIONAL_INFORMATION,
  STATUS_EMAIL_ISSUE,
  STATUS_EMAIL_EXPIRATION,
  STATUS_APPROVE_CRIME,
  STATUS_APPROVE_GARAGE,
  STATUS_APPROVE_EPLI,
  STATUS_APPROVE_CYBER,
  STATUS_APPROVE_PRO_LIAB,
  STATUS_APPROVE_POL_LIAB,
  STATUS_APPROVE_IM,
  STATUS_EMAIL_VERIFICATION_NOT_COMPLIANT,
  STATUS_EMAIL_VERIFICATION_NOT_VERIFIED,
  STATUS_EMAIL_VERIFICATION_EXPIRING,
  STATUS_EMAIL_VERIFICATION_LAPSED,
  DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
  DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
  DO_NOT_CONTACT_OPTION_IS_LEAD,
  DO_NOT_CONTACT_OPTION_NOT_OPEN,
  DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
  DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
  DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
  DO_NOT_CONTACT_OPTION_NONE,
  STATUS_POLICY_STATUS_CHANGE,
  STATUS_COMPLIANCE_STATUS_CHANGE
} from "../../../../assets/const/status";
import { DOC_NAMING } from "@assets/const/ui";
import { format } from "date-fns";

export default function ActivityList(props) {
  const { franchiseeData } = props;
  const dataSourceActivity = (franchiseeData.activities || []).sort(
    (itemA, itemB) => {
      if (new Date(itemA.date) > new Date(itemB.date)) {
        return -1;
      } else {
        return 1;
      }
    }
  );

  function recordStatusUpdate(doNotContactStatus) {
    switch (doNotContactStatus) {
      case DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER:
        return "Rikor Customer";
      case DO_NOT_CONTACT_OPTION_INACTIVE_ZEE:
        return "Inactive Zee";
      case DO_NOT_CONTACT_OPTION_IS_LEAD:
        return "New Lead";
      case DO_NOT_CONTACT_OPTION_NOT_OPEN:
        return "Not Open";
      case DO_NOT_CONTACT_OPTION_NOT_COVERAGE:
        return "Not Coverage";
      case DO_NOT_CONTACT_OPTION_CANADA_BUSINESS:
        return "Canada Business";
      case DO_NOT_CONTACT_OPTION_CANCEL_POLICY:
        return "Cancel Policy";
      case DO_NOT_CONTACT_OPTION_NONE:
        return "Non-Rikor Customer";
      default:
        return doNotContactStatus;
    }
  }

  function handleStatus(status, record) {
    switch (status) {
      case STATUS_CHANGE:
        return `Franchisee record status was changed to: ${recordStatusUpdate(
          record?.note
        )}`;
      case STATUS_EMAIL:
        return "Email was sent";
      case STATUS_UPLOAD:
        return `Franchisee uploaded document ${
          record?.note ? `(${DOC_NAMING[record.note]})` : ""
        }`;
      case STATUS_APPROVE:
        return "Documentation was approved";
      case STATUS_APPROVE_LIABILITY:
        return "Liability Documentation was approved";
      case STATUS_APPROVE_AUTO:
        return "Auto Document was approved";
      case STATUS_APPROVE_WORKERS_COMPENSATION:
        return "Workers Compensation Document was approved";
      case STATUS_APPROVE_UMBRELLA:
        return "Umbrella Document was approved";
      case STATUS_APPROVE_CRIME:
        return "Crime Document was approved";
      case STATUS_APPROVE_GARAGE:
        return "Garage Document was approved";
      case STATUS_APPROVE_EPLI:
        return "Employment Practices Liability was approved";
      case STATUS_APPROVE_CYBER:
        return "Cyber Liability was approved";
      case STATUS_APPROVE_PRO_LIAB:
        return "Professional Liability was approved";
      case STATUS_APPROVE_POL_LIAB:
        return "Pollution Liability was approved";
      case STATUS_APPROVE_IM:
        return "Inland Marine was approved";
      case STATUS_APPROVE_ADDITIONAL_INFORMATION:
        return "Additional Information was approved";
      case STATUS_EMAIL_ISSUE:
        return "Any Issue was detected in uploaded documentation";
      case STATUS_EMAIL_EXPIRATION:
        return "Document was expired";
      case STATUS_EMAIL_VERIFICATION_NOT_COMPLIANT:
        return "Verification Email(Not Compliant) was sent";
      case STATUS_EMAIL_VERIFICATION_NOT_VERIFIED:
        return "Verification Email(Not Verified) was sent";
      case STATUS_EMAIL_VERIFICATION_EXPIRING:
        return "Verification Email(Expiring) was sent";
      case STATUS_EMAIL_VERIFICATION_LAPSED:
        return "Verification Email(Lapsed) was sent";
      case STATUS_POLICY_STATUS_CHANGE:
        return `Policy Status Changed > ${record?.nextStatus}`;
      case STATUS_COMPLIANCE_STATUS_CHANGE:
        return `Compliance Status Changed > ${record?.nextStatus}`;
      default:
        return status;
    }
  }

  const columnsActivity = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (field, record) => (
        <div>{format(new Date(field), "PPP, HH:mm")}</div>
      )
    },
    {
      title: "Activity",
      dataIndex: "action",
      key: "action",
      render: (status, record) => <span>{handleStatus(status, record)}</span>
    }
  ];

  return (
    <Card title="Activity">
      <Table dataSource={dataSourceActivity} columns={columnsActivity} />
    </Card>
  );
}
